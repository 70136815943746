$red: #007bff;

$primary: $red;

$grid-gutter-width: 14px;

$input-btn-padding-y-lg: 10.5px;
$input-btn-font-size-lg: 18px;

$input-bg: #f1f3f8;
$input-border-color: $input-bg;

$input-placeholder-color: #9eaabd;

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

* {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
}

@import 'node_modules/bootstrap/scss/bootstrap-grid';

@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/spinners';

@import './fonts';

@import './typo';

@import './header';
@import './footer';
@import './icons';

@import './top-panel';
@import './reviews';
@import './statistics';
@import './whythem';
@import './registration-box';

@import './carousel';

@import './popup';
@import './selectric';
@import './custom-flex-container';

body {
  font-family: 'SF Pro Display';
  font-size: 14px;
  line-height: 26px;

  @include media-breakpoint-up(md) {
    font-size: 18px;
    line-height: 26px;
  }
}

.visibility-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.wrapper {
  overflow: hidden;
  .container {
    @media (max-width: 991.98px) {
      padding-left: 14px;
      padding-right: 14px;
    }
  }

  a {
    text-decoration: none;
  }
}

.text {
  &--normal {
    font-weight: 400 !important;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--success {
    color: #b0db1f;
  }
}

button,
input,
select,
textarea {
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: #f1f3f8 !important;
  }
}

.form-control {
  font-family: 'Source Sans Pro' !important;
}

.top-panel__form-group .selectric .label {
  color: #818ba5;
  font-weight: 400;
  font-family: 'SF Pro Display' !important;
  font-size: 16px;
  line-height: 42px;
  height: 42px;

  @include media-breakpoint-up(sm) {
    line-height: 64px;
    height: 64px;
    font-size: 18px;
  }
}

.top-panel__form-group .selectric .button {
  height: 42px;
  width: 42px;

  @include media-breakpoint-up(sm) {
    line-height: 64px;
    height: 64px;
  }
}

.top-panel__form-group .selectric-wrapper {
  margin-bottom: 10px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 20px;
  }

  &.error {
    & .selectric {
      border: 1px solid #ff5159;
      background-color: rgba(#ff6269, 0.1);
    }

    &:focus {
      & .selectric {
        border: 1px solid #ff5159 !important;
      }
    }
  }
}
