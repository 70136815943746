.custom-flex-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  row-gap: 10px;

  &__data {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 20px;
  }

  &__form {
    margin-top: 0;
  }

  &__button-submit {
    margin-left: auto;
    margin-right: auto;
  }

  &__courses-list-wrapper {
    position: relative;
  }

  &__courses-item-highlighted {
    color: #000 !important;
    font-weight: 700;
    pointer-events: none;
  }

  &__courses-list {
    position: absolute;
    left: 0;
    top: calc(100% + 2px);
    right: 0;
    border-radius: 16px;
    background-color: #f6f9ff;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    border: 1px solid #bbc2d1;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 10px;
    list-style-type: none;
    margin: 0;
    z-index: 10;
    color: rgba(13, 29, 74, 0.5);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s ease, opacity 0.3s ease;

    &--opened {
      visibility: visible;
      opacity: 1;
    }

    & li {
      color: inherit;
      font-size: 14px;
      transition: color 0.3s ease;
      overflow-wrap: break-word;
      cursor: pointer;

      &:hover {
        color: #0d1d4a;
      }

      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }
    }
  }

  &__button,
  &__button-submited {
    @include media-breakpoint-up(sm) {
      max-width: none;
    }
  }

  &__files-wrapper {
    display: flex;
    align-items: center;
    column-gap: 20px;
    height: 100%;
    padding-bottom: 20px;

    @include media-breakpoint-up(sm) {
      padding-bottom: 10px;
    }

    & label {
      display: flex;
      column-gap: 8px;
      align-items: center;
      justify-content: flex-start;
      color: #818ba5;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
      cursor: pointer;

      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }

      & span {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-decoration: underline;
        text-underline-offset: 5px;
      }
    }
  }

  &__files-count {
    color: #818ba5;
    font-size: 18px;
  }

  &__add-text {
    margin-right: auto;
    margin-left: 0;

    @include media-breakpoint-up(sm) {
      margin-left: 0;
    }

    & p {
      text-align: center;
      margin-bottom: 0;
      color: rgba(0, 0, 0, 0.5);
    }

    &--right {
      margin-left: auto;
      margin-right: 0;

      & p {
        text-align: right;
      }
    }
  }
}
