.top-panel {
  display: flex;
  flex-direction: column;

  position: relative;

  overflow: hidden;

  padding-top: 30px;

  font-size: 16px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.5);

  @include media-breakpoint-up(md) {
    /*     height: 656px; */
    min-height: 656px;
  }

  &:after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    display: block;

    width: 280px;
    height: 100px;

    background: url('../img/vector2.svg') no-repeat 0 50%;

    @include media-breakpoint-up(md) {
      z-index: 1;

      width: 350px;

      background-position-x: 0;
    }
    @include media-breakpoint-up(xl) {
      right: auto;
      left: 50%;

      width: 500px;

      transform: translateX(280px);
    }
  }

  &:before {
    content: '';

    display: block;
  }

  & .container {
    position: relative;
    z-index: 10;
  }

  &__second-form {
    display: none;

    &.show {
      display: flex;
    }
  }

  &__top {
    /*  margin-top: 57px; */
    margin-top: 20px;
  }

  &-bg {
    display: none;

    @include media-breakpoint-up(md) {
      position: absolute;
      right: 0;
      bottom: -70px;
      z-index: 2;

      display: block;

      width: 720px;
      height: 645px;

      background-image: url('../img/bg1.png');
      background-repeat: no-repeat;
      background-position: 0 0;
      transform: scale(0.8) translateX(50%);

      &.active {
        background-position-y: -647px;
      }
    }
    @include media-breakpoint-up(xl) {
      right: auto;
      bottom: 0;
      left: 50%;

      transform: scale(1) translateX(0%);
    }

    &-1 {
      background-image: url('../img/bg1.png');
    }

    &-2 {
      background-image: url('../img/bg2.png');
    }

    &-3 {
      background-image: url('../img/bg3.png');
    }

    &-4 {
      background-image: url('../img/bg4.png');
    }
  }

  &-blot {
    display: none;

    @include media-breakpoint-up(md) {
      position: absolute;
      right: 0;
      bottom: -100px;
      z-index: 1;

      display: block;

      width: 760px;
      height: 100%;

      background: url('../img/bg-top.png') no-repeat 0 30%;
      transform: scale(0.8) translateX(50%);
    }
    @include media-breakpoint-up(xl) {
      right: auto;
      top: auto;
      bottom: 0;
      /*       top: 0;
      bottom: auto; */
      left: 50%;
      /* height: 615px; */
      height: 100%;
      min-height: 615px;
      transform: scale(1) translateY(30px) translateX(5px);
    }
  }

  & h1 {
    margin-bottom: 17px;

    font-weight: 800;
    font-size: 36px;
    line-height: 42px;

    letter-spacing: 0.035em;

    color: #0d1d4a;

    @include media-breakpoint-up(sm) {
      font-size: 48px;
      line-height: 58px;
    }
    & small {
      font-size: 30px;
    }
  }

  &__desc {
    max-width: 503px;

    & p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &_logo {
    max-width: 100%;
    /*         margin-bottom: 31px;

        @include media-breakpoint-up(xl) {
            margin-bottom: 70px;
        } */
  }

  & .btn {
    position: relative;
    z-index: 2;

    display: block;
    float: right;

    width: 97px;
    /*         margin-bottom: 31px; */
    padding-top: 11px;
    margin-bottom: 0;
    padding-bottom: 11px;
    box-sizing: border-box;

    font-weight: 700;
    font-size: 13px;
    line-height: 15px;

    text-transform: uppercase;

    color: #353df0;
    border: 1px solid #353df0;
    border-radius: 16px;
    background: #fff;

    /*         @include media-breakpoint-up(xl) {
            margin-bottom: 70px;
        } */

    &-login {
      display: none;

      @include media-breakpoint-up(xl) {
        position: absolute;
        top: 30px;
        right: 100px;
        z-index: 10;

        display: block;

        width: 97px;
        margin-bottom: 31px;
        padding-top: 11px;
        padding-bottom: 11px;
        box-sizing: border-box;

        font-weight: 700;
        font-size: 13px;
        line-height: 15px;

        text-align: center;
        text-transform: uppercase;

        color: #353df0;
        border: 1px solid #353df0;
        border-radius: 16px;
        background: #fff;
      }
    }
  }

  &__form {
    max-width: 545px;
    margin-top: 35px;

    font-size: 16px;
    line-height: 19px;

    color: #000;

    @include media-breakpoint-up(xl) {
      margin-top: 71px;
    }

    &-hidden {
      display: none;
    }

    &__prices {
      max-width: 408px;
    }

    &__price {
      display: block;

      margin-top: 5px;
      margin-bottom: 32px;

      font-weight: 800;
      font-size: 22px;
      line-height: 122%;

      letter-spacing: 0.035em;

      color: #007bff;
    }

    & label {
      display: block;

      margin-bottom: 4px;

      font-size: 12px;
      line-height: 14px;

      color: #0d1d4a;

      &.error {
        display: none !important;
      }

      & span {
        font-weight: 700;

        color: #007bff;
      }
    }

    &-input {
      display: block;

      width: 100%;
      margin-bottom: 10px;
      padding: 10px;
      box-sizing: border-box;

      font-weight: 400;
      font-size: 16px;
      line-height: 22px;

      color: rgba(13, 29, 74, 0.5);
      border: 1px solid #bbc2d1;
      border-radius: 16px;
      background: #f6f9ff;

      @include media-breakpoint-up(sm) {
        margin-bottom: 20px;
        padding: 20px;

        font-size: 18px;
        line-height: 21px;
      }

      &.error {
        border: 1px solid #ff5159;
        background-color: rgba(#ff6269, 0.1);

        &:focus {
          border: 1px solid #ff5159 !important;
        }
      }

      &::placeholder {
        color: #818ba5;
      }
    }

    &-group {
      & .error-text {
        display: block;

        margin-top: -20px;
        margin-bottom: 8px;
        margin-left: 16px;

        font-size: 12px;
        line-height: 1;

        color: #ff5159;

        &.hidden {
          display: none;
        }
      }
    }

    &-btn {
      display: block;

      width: 100%;
      padding: 10px 10px;

      font-weight: 700;
      font-size: 14px;
      line-height: 20px;

      cursor: pointer;
      text-transform: uppercase;

      color: #fff;
      border: none;
      border-radius: 16px;
      background: #007bff;
      margin-bottom: 20px;

      @include media-breakpoint-up(sm) {
        max-width: 220px;
        padding: 18px 10px;

        font-size: 18px;
        line-height: 26px;
      }

      &:hover {
        background-color: darken(#007bff, 10%);
      }
    }
  }
}
